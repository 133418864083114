<template>
    <div class="flex flex-wrap">
        <svg @click="$emit('update:modelValue', 0)" class="block h-12 w-12 mx-3 cursor-pointer hover:text-red-300" :class="[ modelValue == 0 ? 'text-red-400': 'text-gray-400']" fill="currentColor" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64" xml:space="preserve"><path d="M32 1.3C15 1.3 1.3 15 1.3 32S15 62.8 32 62.8 62.8 49 62.8 32 49 1.3 32 1.3zm0 58C17 59.3 4.8 47 4.8 32S17 4.8 32 4.8 59.3 17 59.3 32 47 59.3 32 59.3z"/><path d="M32 38.2c-5.3 0-10.4 2-14.3 5.6-.7.7-.8 1.8-.1 2.5.7.7 1.8.8 2.5.1 3.2-3 7.4-4.6 11.9-4.6 4.4 0 8.6 1.6 11.9 4.6.3.3.8.5 1.2.5.5 0 .9-.2 1.3-.6.7-.7.6-1.8-.1-2.5-3.9-3.6-9-5.6-14.3-5.6zM20.1 26.7c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zM43.9 18.8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.9-4-4-4z"/></svg>
        <svg @click="$emit('update:modelValue', 1)" class="block h-12 w-12 mx-3 cursor-pointer hover:text-yellow-200" :class="[ modelValue == 1 ? 'text-yellow-300': 'text-gray-400']" fill="currentColor" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64" xml:space="preserve"><path d="M32 1.3C15 1.3 1.3 15 1.3 32S15 62.8 32 62.8 62.8 49 62.8 32 49 1.3 32 1.3zm0 58C17 59.3 4.8 47 4.8 32S17 4.8 32 4.8 59.3 17 59.3 32 47 59.3 32 59.3z"/><path d="M45.2 43.4H18.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h26.4c1 0 1.8-.8 1.8-1.8s-.9-1.8-1.8-1.8zM20.1 26.7c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zM43.9 18.8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4c0-2.3-1.8-4-4-4z"/></svg>
        <svg @click="$emit('update:modelValue', 2)" class="block h-12 w-12 mx-3 cursor-pointer hover:text-green-300" :class="[ modelValue == 2 ? 'text-green-400': 'text-gray-400']" fill="currentColor" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64" xml:space="preserve"><path d="M32 1.3C15 1.3 1.3 15 1.3 32S15 62.8 32 62.8 62.8 49 62.8 32 49 1.3 32 1.3zm0 58C17 59.3 4.8 47 4.8 32S17 4.8 32 4.8 59.3 17 59.3 32 47 59.3 32 59.3z"/><path d="M43.9 43.9c-3.2 3-7.4 4.6-11.9 4.6-4.4 0-8.6-1.6-11.9-4.6-.7-.7-1.8-.6-2.5.1s-.6 1.8.1 2.5a21.08 21.08 0 0 0 28.6 0c.7-.7.8-1.8.1-2.5-.7-.7-1.8-.7-2.5-.1zM20.2 26.7c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zM43.9 18.8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"/></svg>
    </div>
</template>

<script>
export default {
    name: "EmojiRating",
    props: {
        modelValue: Number,
    },

    mounted() {
        this.$nextTick(() => {
            this.$emit('update:modelValue', 1);
        });
    },
};
</script>
