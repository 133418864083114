<template>
    <div>
        <textarea
            :value="modelValue"
            @input="e => $emit('update:modelValue', e.target.value)"
            :placeholder="placeholder"
            class="block w-full p-3 border-gray-300 rounded-md shadow-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white focus:ring-blue-500 focus:border-blue-500"
            :rows="rows"
        />
    </div>
</template>

<script>
export default {

    props: {
        modelValue: {
            type: String,
        },
        placeholder: {
            type: String,
            default: "",
        },
        rows: {
            type: Number,
            default: 5,
        },
    },

}
</script>

<style>

</style>