<template>
    <div class="flex flex-wrap">
        <button type="button" v-for="i in stars" :class="{ 'mr-1': i < stars }" @click="$emit('update:modelValue', i)" :key="i">
            <svg class="block h-8 w-8 mx-1" :class="[ modelValue >= i ? 'text-yellow-400': 'text-gray-400']" fill="currentColor" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
        </button>
    </div>
</template>

<script>
export default {
    name: "StarRating",
    props: {
        modelValue: Number,
        stars: Number,
    },
};
</script>
