<template>
    <div class="p-5">
        <div class="mx-4 p-4">
            <div class="flex items-center">
                <template v-for="s1 in step - 1" :key="s1">
                    <div class="flex items-center text-red-700 relative group">
                        <button @click="$emit('selected', s1)" class="focus:outline-none group-hover:bg-red-700 group-hover:text-white flex items-center justify-center font-semibold text-center text-lg rounded-full h-12 w-12 py-3 border-2 border-red-700">
                            {{ s1 }}
                        </button>
                    </div>
                    <div v-if="s1 == step - 1" class="flex-auto h-0.5 bg-gradient-to-r from-red-700 to-blue-800"></div>
                    <div v-else class="flex-auto h-0.5 bg-gradient-to-r from-red-700 via-blue-800 to-red-700"></div>
                </template>
                <div class="flex items-center text-white relative">
                    <button @click="$emit('selected', step)" class="focus:outline-none flex items-center justify-center font-semibold text-center text-lg rounded-full h-12 w-12 py-3 border-2 bg-blue-800 border-blue-800">
                        {{ step }}
                    </button>
                </div>
                <template v-for="s2 in total - step" :key="s2">
                    <div v-if="s2 - 1 < step" class="flex-auto h-0.5 bg-gradient-to-r from-blue-800 via-gray-300 to-gray-300"></div>
                    <div v-else class="flex-auto h-0.5 bg-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative group">
                        <button @click="$emit('selected', s2 + step)" class="focus:outline-none flex items-center justify-center font-semibold group-hover:bg-blue-800 group-hover:border-blue-800 group-hover:text-white text-center text-lg rounded-full h-12 w-12 py-3 border-2 border-gray-300">
                            {{ s2 + step }}
                        </button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Stepper',

    props: {
        step: Number,
        total: Number,
    },
}
</script>

<style>
</style>
