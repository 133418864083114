<template>
    <div v-if="!error" class="flex flex-col items-center justify-between gap-4 pb-6">
        <Stepper v-if="step != (questions.length + 1)" class="w-full sm:w-1/2" :step="step" :total="questions.length" @selected="s => step = s" />
        <div v-for="(page, i) in questions" :key="i" class="flex flex-col items-center justify-between md:w-2/5 w-full px-4 md:px-0 gap-6">
            <template v-if="step == (i + 1)">
                <template v-for="(question, j) in page" :key="`${i}-${j}`">
                    <div class="flex flex-col items-center justify-between w-full">
                        <H1 v-if="question.type == 'h1'" class="mt-4" :label="question.text" />
                        <H2 v-else-if="question.type == 'h2'" class="mt-4" :label="question.text" />
                        <H3 v-else-if="question.type == 'h3'" class="mt-4" :label="question.text" />
                        <StarRating :stars="(question.stars && parseInt(question.stars) > 0) ? parseInt(question.stars) : 5" v-else-if="question.type == 'stars'" class="mt-6" v-model="form[question.name]" />
                        <EmojiRating v-else-if="question.type == 'emoji'" class="mt-6" v-model="form[question.name]" />
                        <TextBox v-else-if="question.type == 'textbox'" class="w-full mt-6 border-2 border-gray-500 rounded-lg" v-model="form[question.name]" />
                    </div>
                </template>
                <div class="flex items-center justify-between mt-8 gap-4">
                    <Button v-if="step > 1" type="success" @click="previousStep">Voltar</Button>
                    <Button v-if="step < questions.length" type="success" @click="nextStep">Seguinte</Button>
                    <Button v-else type="success" @click="completeSurvey">Terminar</Button>
                </div>
            </template>
        </div>
        <div v-if="step == (questions.length + 1)" class="flex flex-col items-center justify-between w-2/5 gap-6">
            <H1 class="mt-4" label="Obrigado pelo tempo que nos dispensou. Comprometemo-nos a ter em consideração as suas respostas para desenvolver ações que nos permitam oferecer-lhe uma experiência de excelência." />
        </div>
    </div>
    <div v-else>Erro a submeter formulário</div>
</template>

<script>
import H1 from './forms/H1.vue';
import H2 from './forms/H2.vue';
import H3 from './forms/H3.vue';
import StarRating from './forms/StarRating.vue';
import EmojiRating from './forms/EmojiRating.vue';
import TextBox from './forms/TextBox.vue';
import Button from './forms/Button.vue';
import Stepper from './Stepper.vue';
import Swal from 'sweetalert2';

export default {

    components: {
        H1,
        H2,
        H3,
        StarRating,
        EmojiRating,
        TextBox,
        Button,
        Stepper,
    },

    props: {
        uuid: String,
        questions: Array,
    },

    data: () => ({
        url: process.env.VUE_APP_API_URL,
        error: false,
        step: 1,
        form: {},
    }),

    mounted() {
        this.questions.forEach(page => {
            page.forEach(question => {
                if(question.name) {
                    this.form[question.name] = "";
                }
            });
        });
    },

    methods: {
        nextStep() {
            this.step++;
            window.scrollTo(0,0);
        },

        previousStep() {
            this.step--;
            window.scrollTo(0,0);
        },

        completeSurvey() {
            Swal.fire({
                title: 'Tem a certeza que pretende submeter este inquérito?',
                text: "Esta ação é irreversível e não poderá ser mais consultado!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#059669',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, submeter',
                cancelButtonText: 'Cancelar',
                }).then((result) => {
                if (result.isConfirmed) {
                    this.sendSurvey();
                }
            });
        },

        async sendSurvey() {
            const answer = this.form;
            const body = new FormData();
            body.append('answer', JSON.stringify(answer));
            const res = await fetch(
                `${this.url}survey?token=${localStorage.getItem("api_token")}&uuid=${this.uuid}`,
                { method: 'POST', body }
            );
            const data = (await res.json());
            if(data.status == 'success') {
                this.step++;
                window.scrollTo(0,0);
                this.error = false;
            } else {
                this.error = true;
            }
        },
    },
}
</script>
