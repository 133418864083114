<template>
    <a href="http://redfenix.pt" class="h-12 flex md:flex-nowrap flex-wrap items-center justify-center md:gap-10 gap-x-4 gap-y-1">
        <img src="../assets/brands/redfenix.svg" alt="RedFénix" class="h-full py-1.5" />
        <img src="../assets/brands/kibur.svg" alt="Kibur" class="h-full" />
        <img src="../assets/brands/adspot.svg" alt="AdSpot" class="h-full" />
        <img src="../assets/brands/becurious.svg" alt="BeCurious" class="h-full" />
        <img src="../assets/brands/rewind.png" alt="Rewind" class="h-full" />
        <img src="../assets/brands/rcam.svg" alt="rcam" class="h-full" />
        <img src="../assets/brands/esa.svg" alt="ESA" class="h-full" />
    </a>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style>
</style>
