<template>
    <div>
        <div class="block">
            <div class="flex items-center justify-between w-full">
                <span v-if="required" class="flex items-center mr-1 text-lg font-bold text-red-500">
                    <span class="hidden mb-2 mr-2 text-xs font-normal sm:block">campo obrigatório</span>
                    *
                </span>
            </div>
        </div>
        <div class="relative">
            <h1 class="text-2xl" v-html="label"></h1>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        label: {
            type: String,
        },
        required: Boolean,
    },

}
</script>
