<template>
    <div class="flex flex-col items-center justify-between h-full w-full">
        <div class="flex flex-col items-center justify-start flex-1 w-full">
            <img class="h-48 my-8 md:px-0 px-4" alt="Logo Redfénix" src="./assets/logo_original.svg">
            <hr class="w-full h-1 bg-gradient-to-r from-red-500 to-blue-800" />
            <template v-if="!loading">
                <div v-if="authenticated" class="w-full mt-8 text-center">
                    <Form v-if="success" :questions="formQuestions" :uuid="form.uuid" />
                    <h1 v-else class="text-2xl tracking-wide bold">
                        {{ {
                            'INVALID_FORM': 'Este formulário não é válido.',
                            'FORM_FILLED': 'Este formulário já não se encontra disponível.',
                        }[error.type] }}
                    </h1>
                </div>
                <div v-else class="mt-8">
                    <h1 class="text-2xl tracking-wide bold">Erro de autenticação. Por favor tente mais tarde.</h1>
                </div>
            </template>
            <div v-else class="mt-8 text-center">
                <h1 class="text-2xl tracking-wide bold">A carregar...</h1>
                <p class="md:text-xl text-lg tracking-wide">Em caso de demora, atualize a página.</p>
                <span class="md:text-lg text-base tracking-tight">(shift + f5)</span>
            </div>
        </div>
        <Footer class="w-full" />
    </div>
</template>

<script>
import Form from './components/Form.vue';
import Footer from './components/Footer.vue';

export default {
    name: 'App',
    components: {
        Form,
        Footer,
    },

    data: () => ({
        uuid: null,
        baseUrl: process.env.VUE_APP_BASE_URL,
        url: process.env.VUE_APP_API_URL,
        username: process.env.VUE_APP_API_USERNAME,
        key: process.env.VUE_APP_API_KEY,
        authenticated: false,
        token: null,
        loading: true,
        success: false,
        error: null,
        form: null,
        formQuestions: null,
    }),

    async mounted() {
        this.loading = true;
        this.uuid = this.queryParam('uuid');
        await this.login();
        await this.loadForm();
        this.loading = false;
    },

    methods: {
        queryParam(key) {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            return params.get(key);
        },
        async login() {
            const body = new FormData();
            body.append("username", this.username);
            body.append("key", this.key);
            try {
                const res = await fetch(this.url + "login", { method: 'POST', body });
                const token = (await res.json()).api_token;
                localStorage.setItem("api_token", token);
                this.token = token;
                this.authenticated = true;
            } catch(e) {
                console.log(e);
                this.token = null;
                this.authenticated = false;
            }
        },
        async loadForm() {
            const res = await fetch(`${this.url}survey?token=${this.token}&uuid=${this.uuid}`);
            const data = await res.json();
            this.success = data.status == 'success';
            if(this.success) {
                this.form = data.data;
                await this.loadQuestions();
            } else {
                this.error = data.error;
            }
        },
        async loadQuestions() {
            const res = await fetch(this.baseUrl + `/forms/${this.form.ref}.json`);
            this.formQuestions = await res.json();
        },
    },
}
</script>

<style>
</style>
