<template>
    <div class="relative">
        <button
            :disabled="disabled"
            class="flex items-center justify-center w-full px-2 py-2 font-medium tracking-wide text-center transition-colors duration-200 transform rounded-md focus:outline-none"
            :class="
                type === 'primary' ? 'text-white border-2 border-transparent bg-blue-600 dark:bg-blue-700 hover:bg-blue-500 dark:hover:bg-blue-600 focus:bg-blue-500 dark:focus:bg-blue-600'
                : type === 'primary-outline' ? 'text-blue-500 bg-transparent dark:bg-transparent dark:text-gray-100 border-2 border-blue-300 dark:border-1 dark:border-blue-600 dark:hover:border-transparent hover:border-transparent  border-dashed dark:border-dashed hover:bg-blue-200 dark:hover:bg-blue-700 '
                : type === 'secondary' ? 'text-white border-2 border-transparent bg-gray-600 dark:bg-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600 focus:bg-gray-500 dark:focus:bg-gray-600'
                : type === 'secondary-outline' ? 'text-gray-500 bg-transparent dark:bg-transparent dark:text-gray-100 border-2 border-gray-300 dark:border-1 dark:border-gray-600 dark:hover:border-transparent hover:border-transparent dark:focus:border-transparent focus:border-transparent border-dashed dark:border-dashed hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-200 dark:focus:bg-gray-700'
                : type === 'success' ? 'text-white border-2 border-transparent bg-green-600 dark:bg-green-700 hover:bg-green-700 dark:hover:bg-green-600 focus:bg-green-700 dark:focus:bg-green-600'
                : type === 'success-outline' ? 'text-green-600 bg-transparent dark:bg-transparent dark:text-gray-100 border-2 border-green-500 dark:border-1 dark:border-green-700 dark:hover:border-transparent hover:border-transparent dark:focus:border-transparent focus:border-transparent border-dashed dark:border-dashed hover:bg-green-200 dark:hover:bg-green-700 focus:bg-green-200 dark:focus:bg-green-700'
                : type === 'cancel' ? 'text-white border-2 border-transparent bg-red-500 dark:bg-red-800 hover:bg-red-600 dark:hover:bg-red-700 focus:bg-red-600 dark:focus:bg-red-700'
                : type === 'cancel-outline' ? 'text-red-500 bg-transparent dark:bg-transparent dark:text-gray-100 border-2 border-red-300 dark:border-1 dark:border-red-700 dark:hover:border-transparent hover:border-transparent dark:focus:border-transparent focus:border-transparent border-dashed dark:border-dashed hover:bg-red-200 dark:hover:bg-red-800 focus:bg-red-200 dark:focus:bg-red-800'
                : type === 'edit' ? 'text-gray-800 border-2 border-transparent bg-yellow-300 dark:bg-yellow-400 hover:bg-yellow-400 dark:hover:bg-yellow-300 focus:bg-yellow-400 dark:focus:bg-yellow-300 dark:text-gray-800'
                : type === 'edit-outline' ? 'text-gray-700 bg-transparent dark:bg-transparent dark:text-gray-100 border-2 border-gray-400 dark:border-1 dark:border-gray-400 dark:hover:border-transparent hover:border-transparent dark:focus:border-transparent focus:border-transparent border-dashed dark:border-dashed hover:bg-blue-300 dark:hover:bg-blue-400 focus:bg-blue-300 dark:focus:bg-blue-400'
                : type === 'info' ? 'text-white border-2 border-transparent bg-indigo-600 dark:bg-indigo-700 hover:bg-indigo-700 dark:hover:bg-indigo-600 focus:bg-indigo-700 dark:focus:bg-indigo-600'
                : type === 'info-outline' ? 'text-indigo-600 bg-transparent dark:bg-transparent dark:text-gray-100 border-2 border-indigo-400 dark:border-1 dark:border-indigo-700 dark:hover:border-transparent hover:border-transparent dark:focus:border-transparent focus:border-transparent border-dashed dark:border-dashed hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:bg-indigo-200 dark:focus:bg-indigo-800'
                : ''
            "
        >
            <slot :open="open" />
        </button>
    </div>
</template>

<script>
export default {

    props: {
        type: {
            type: String,
            default: 'primary',
            validator: type => [
                'primary',
                'primary-outline',
                'secondary',
                'secondary-outline',
                'success',
                'success-outline',
                'cancel',
                'cancel-outline',
                'edit',
                'edit-outline',
                'info',
                'info-outline',
            ].indexOf(type) !== -1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

}
</script>

<style>

</style>